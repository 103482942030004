export const generateAppConfig = () =>{
    let exportResult = {
        appRepoLocation:"http://localhost:3001",
        backendUrl: "http://localhost:3100",
        agentBackendUrl: "http://localhost:4041",
        jsReportUrl: "http://localhost:5488"
    };
    if(process.env.REACT_APP_PRODUCTION_MODE ==='TEST'){
        exportResult.appRepoLocation = "https://sg-app.tunabay.com.my";
        exportResult.backendUrl = "https://sg-api.tunabay.com.my/admin";
        exportResult.agentBackendUrl = "https://sg-api.tunabay.com.my/business";
        exportResult.userPortalBackendUrl = "https://sg-api.tunabay.com.my/app";
        exportResult.jsReportUrl= "https://sg-report.tunabay.com.my";
    }

    if(process.env.REACT_APP_PRODUCTION_MODE ==='PROD'){
        exportResult.appRepoLocation = "https://app.tunabay.com.my";
        exportResult.backendUrl = "https://api.tunabay.com.my/admin";
        exportResult.agentBackendUrl = "https://api.tunabay.com.my/business";
        exportResult.userPortalBackendUrl = "https://api.tunabay.com.my/app";
        exportResult.jsReportUrl= "https://resort.tunabay.com.my";
    }
    
    return exportResult;
}